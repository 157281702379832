var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('base-material-card',{attrs:{"title":"Buat Kategori","icon":"mdi-calendar-check"}},[_c('v-form',[_c('v-card',[[_c('validation-observer',[_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Masukan Nama","error-messages":errors,"label":"Name","required":"","type":"text"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_c('validation-provider',{attrs:{"name":"Type","rules":"required|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.type_items,"error-messages":errors,"label":"Type","required":"","item-text":"name","item-value":"id"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})]}}])}),_c('validation-provider',{attrs:{"name":"Status","rules":"required|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.status_items,"error-messages":errors,"label":"Status","required":"","item-text":"name","item-value":"id"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]}}])}),_c('validation-provider',{attrs:{"name":"Komunitas","rules":"required|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.komunitas.data,"error-messages":errors,"label":"Komunitas","required":"","item-text":"name","item-value":"id"},model:{value:(_vm.komunitas_id),callback:function ($$v) {_vm.komunitas_id=$$v},expression:"komunitas_id"}})]}}])}),_c('div',{staticClass:"d-flex justify-end mt-6 mb-5 mr-6"},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isValid},on:{"click":_vm.sendCategory}},[_vm._v(" Buat ")])],1)],1)],1)]],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }