<template>
  <v-app>
    <!--  -->
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <!--  -->
          <base-material-card
            title="Buat Kategori"
            icon="mdi-calendar-check"
          >
            <v-form>
              <v-card>
                <template>
                  <validation-observer>
                    <v-form v-model="isValid">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <v-text-field
                          v-model="name"
                          placeholder="Masukan Nama"
                          :error-messages="errors"
                          label="Name"
                          required
                          type="text"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Type"
                        rules="required|"
                      >
                        <v-select
                          v-model="type"
                          :items="type_items"
                          :error-messages="errors"
                          label="Type"
                          required
                          item-text="name"
                          item-value="id"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Status"
                        rules="required|"
                      >
                        <v-select
                          v-model="status"
                          :items="status_items"
                          :error-messages="errors"
                          label="Status"
                          required
                          item-text="name"
                          item-value="id"
                        />
                      </validation-provider>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Komunitas"
                        rules="required|"
                      >
                        <v-select
                          v-model="komunitas_id"
                          :items="komunitas.data"
                          :error-messages="errors"
                          label="Komunitas"
                          required
                          item-text="name"
                          item-value="id"
                        />
                      </validation-provider>
                      <div class="d-flex justify-end mt-6 mb-5 mr-6">
                        <v-btn
                          color="primary"
                          :disabled="!isValid"
                          @click="sendCategory"
                        >
                          Buat
                        </v-btn>
                      </div>
                    </v-form>
                  </validation-observer>
                </template>
              </v-card>
            </v-form>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'CreateCategory',
    data () {
      return {
        kategori: {
          meta: {},
          data: {},
        },
        komunitas: [],
        name: '',
        type: '',
        status: '',
        komunitas_id: '',
        type_items: ['event', 'news'],
        status_items: ['draft', 'publish', 'review'],
        emailRules: [value => value !== '' || 'Form Harus Di isi '],
        isValid: false,
      }
    },
    mounted () {
      this.getKomunitas()
    },
    methods: {
      setKomunitas (data) {
        this.komunitas = data
      },
      getKomunitas () {
        axios.get('/v1/community/?sort=-id&offset=999').then(response => {
          this.setKomunitas(response.data)
        })
      },
      sendCategory () {
        axios
          .post('/v1/category', {
            name: this.name,
            type: this.type,
            status: this.status,
            community_id: this.komunitas_id,
            category_id: '2',
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({ path: '/kategori/' + this.type })
              this.$toast.success('Berhasil Di Buat', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            }
          })
      },
    },
  }
</script>

<style></style>
